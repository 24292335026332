import classes from "./Project.module.css";
import PianoCanvas from "./Piano";
import githubLogo from "../assets/githubLogo.png";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import courseManager from "../assets/courseManager.png";
import resumePdf from "../assets/resume.pdf";

const Project = () => {
  const handleButtonClick = () => {
    window.open("https://github.com/Achennn?tab=repositories", "_blank");
  };

  const [messages, setMessages] = useState(["Visitors", "HR", "Interviewers"]);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const nextIndex = (index + 1) % prevMessages.length;
        index = nextIndex;
        return prevMessages;
      });

      return () => clearInterval(interval);
    }, []);
  });

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good morning,");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good afternoon,");
    } else {
      setGreeting("Good evening,");
    }
  }, []);

  return (
    <div className={classes.project}>
      <h1>Projects</h1>
      <p>This part is on the way</p>
      <div className={classes.projectContainer}>
        <div className={classes.reactProject}>
          <p>Course Manager</p>
          <p>Front end - HTML | Back end - php | Database - MySQL</p>
          {/* <p>Back end - php</p>
          <p>Database - MySQL</p> */}
          <img
            src={courseManager}
            alt="course manager"
            className={classes.courseManager}
          />
        </div>
        <div className={classes.blenderProject}>
          <div className={classes.blenderProjectOne}>
            <p>Portfolio - made by React</p>
            <div className={classes.newReactProject}>
              <header className={classes.header}>
                <nav className={classes.nav}>
                  <ul className={classes.list}>
                    <li>
                      <NavLink to="/" className={classes.active}>
                        Achen
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/secret">Secret</NavLink>
                    </li>
                  </ul>
                </nav>
              </header>
              <div>{greeting}</div>
              <Typewriter
                className={classes.message}
                options={{
                  strings: messages,
                  autoStart: true,
                  loop: true,
                  loopCount: Infinity,
                  deleteSpeed: "natural",
                }}
              />
              <div>Please consider Achen.</div>
            </div>
          </div>
          <div className={classes.blenderProjectTwo}>
            <p>Piano - made by Blender</p>
            <PianoCanvas />
          </div>
        </div>
      </div>
      <div className={classes.moreContainer}>
        <div className={classes.resume}>
          <a
            href={resumePdf}
            download="Cheng Huang - Resume"
            target="_blank"
          ></a>
        </div>
        <div className={classes.moreProject}>
          <button className={classes.githubButton} onClick={handleButtonClick}>
            <div className={classes.left}></div>
            <div className={classes.githubGrid}>
              <div className={classes.githubWord}>
                check my other project on
              </div>
              <img
                src={githubLogo}
                alt="Github Logo"
                className={classes.githubLogo}
              />
            </div>
            <div className={classes.right}></div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Project;
