import React from "react";
// import Navigation from "../components/Navigation";

const Secret = () => {
  return (
    <React.Fragment>
      {/* <Navigation /> */}
      <h1 style={{ textAlign: "center" }}>Privacy Policy for "It's Time"</h1>
      <h2>Introduction</h2>
      <p>
        Welcome to "It's Time," a reminder app designed to help you manage your
        tasks efficiently. This privacy policy outlines our practices regarding
        the handling of any personal information that you might provide to us or
        that is generated during your use of the app.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        No Personal Data Collected: "It's Time" does not collect any personal
        data. All reminder data you enter into the app is stored locally on your
        device and is not transmitted to us or any third parties. No Usage Data
        Collected: We do not collect any usage data from your use of "It's
        Time." No Cookies or Tracking Technologies: Our app does not use cookies
        or any similar tracking technologies.
      </p>
      <h2>Data Storage and Security</h2>
      <p>
        Local Storage: All data you enter into "It's Time" is stored locally on
        your device. We do not have access to this data, and it is not stored on
        our servers. Security Measures: As all data is stored on your own
        device, its security is largely dependent on your device's security
        measures. We recommend that you keep your device secure to protect your
        reminder data.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        "It's Time" does not knowingly collect any information from children
        under the age of 13. Since the app does not collect personal data, it is
        suitable for all ages. Changes to This Privacy Policy
      </p>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>
      <h2>Contact Us</h2>
      If you have any questions about this Privacy Policy, please contact us at:
      [achenloveswork@gmail.com]
    </React.Fragment>
  );
};

export default Secret;
