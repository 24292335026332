import classes from "./About.module.css";
import achen2 from "../assets/achen2.jpg";

const About = () => {
  return (
    <div className={classes.about}>
      <h1>About Myself</h1>
      <div className={classes.descirption}>
        <p className={classes.wordsPart}>
          This is Cheng Huang. You can call me Cheng or Achen.
          <br />
          <br />
          Finished master of computer science degree in Auburn University at
          Montgomery. After I graduated, I stayed at my college as an
          instructor, taught 3D models and databases.
          <br />
          <br />
          I'm interested in front end web development. Meanwhile, I love games,
          willing to create and code games too. (Dreaming I can have my own game studio in the future)
        </p>
        <img src={achen2} className={classes.profile} />
      </div>
    </div>
  );
};

export default About;
