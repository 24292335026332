import React, { useState } from "react";
import achen1 from "../assets/achen1.jpg";
import achen2 from "../assets/achen2.jpg";
import achen5 from "../assets/achen5.jpg";
import achen4 from "../assets/achen4.jpg";
import resumePdf from "../assets/resume.pdf";

import classes from "./Achen.module.css";
import Greet from "./Greet";

const Achen = () => {
  return (
    <React.Fragment>
      <main className={classes.main}>
        <ul className={classes.list}>
          <li>
            <img src={achen1} className={classes.achen1} />
            <img src={achen4} className={classes.achen2} />
          </li>
          <li>
            <img src={achen2} className={classes.resume} />
          </li>
          <li>
            <img src={achen5} className={classes.achen3} />
            <a href={resumePdf} download="Cheng Huang - Resume" target="_blank">
              <img src={achen4} className={classes.achen4} />
            </a>
          </li>
        </ul>
        <Greet />
      </main>
    </React.Fragment>
  );
};

export default Achen;
