import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import classes from './MyHome.module.css';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken =
  "pk.eyJ1IjoiaXRzYWNoZW4iLCJhIjoiY2xqc3dlMnJtMHExZzNnbjBjcXZwNGN0YyJ9.LPiCU6p0pRv2EkpB87VkEw";

const MyHome = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-86.2999689, 32.3792233],
      zoom: 8,
      attributionControl: false,
    });

    return () => map.remove();
  }, []);

  return (
    <div ref={mapContainerRef} className={classes.map}/>
  );
};

export default MyHome;
