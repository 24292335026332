import { NavLink } from "react-router-dom";
import classes from "./Navigation.module.css";

const Navigation = () => {
  return (
    <header className={classes.header}>
      <ul className={classes.list}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.active : classes.achen
            }
          >
            Achen
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/secret"
            className={({ isActive }) =>
              isActive ? classes.active : classes.secret
            }
          >
            Secret
          </NavLink>
        </li>
      </ul>
      <div className={classes.listBackground}></div>
      <div className={classes.background}></div>
    </header>
  );
};

export default Navigation;
