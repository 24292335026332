import { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import pianoModel from "../assets/models/piano.glb";

import CanvasLoader from "./Loader";

const Models = () => {
  const groupRef = useRef();
  const piano = useGLTF(pianoModel);

  const rotationSpeed = 0.005;

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y -= rotationSpeed;
    }
  });


  return (
    <group ref={groupRef}>
      <hemisphereLight intensity={0.15} groundColor="black" />
      <pointLight
        intensity={30}
        position={[0, 1, 3]}
        castShadow
        shadow-mapsize={1024}
      />
      <pointLight
        intensity={30}
        position={[2.8, 3, -2]}
        castShadow
        shadow-mapsize={1024}
      />
      <primitive
        object={piano.scene}
        scale={0.4}
        position={[0, 1, 0]}
        rotation={[0, 0.1, 0]}
      />
    </group>
  );
};

const PianoCanvas = () => {
  return (
    <Canvas
      frameloop="demand"
      shadows
      camera={{ position: [5, 0, 13], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={1} />
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          autoRotate
        />
        <Models />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default PianoCanvas;
