import React from "react";
import classes from "./Skill.module.css";
import html from "../assets/HTML5.png";
import css from "../assets/CSS3.png";
import reactLogo from "../assets/reactLogo.png";
import javascript from "../assets/javascript.png";
import threeJs from "../assets/ThreeJs.png";
import aws from "../assets/aws.png";
import blenderLogo from "../assets/blenderLogo.png";
import mysqlLogo from "../assets/mysqlLogo.png";
import cLogo from "../assets/cLogo.png";
import finalCutProLogo from "../assets/finalCutProLogo.png";

const skillsData = [
  { id: 1, name: "HTML", logo: html },
  { id: 2, name: "CSS", logo: css },
  { id: 3, name: "React", logo: reactLogo },
  { id: 4, name: "JavaScript", logo: javascript },
  { id: 5, name: "Three.js", logo: threeJs },
  { id: 6, name: "AWS", logo: aws },
  { id: 7, name: "Blender", logo: blenderLogo },
  { id: 8, name: "MySQL", logo: mysqlLogo },
  { id: 9, name: "C++", logo: cLogo },
  {id: 10, name: "Final Cut Pro", logo: finalCutProLogo },
];

const Skill = () => {
  return (
    <div className={classes.skill}>
      <h1>Skills</h1>
      <div className={classes.skillGrid}>
        {skillsData.map((skill) => (
          <div key={skill.id} className={classes.skillItem}>
            <img src={skill.logo} alt={`${skill.name} Logo`}/>
            <h4 className={`${skill.name}Title`}>{skill.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skill;
