import React from "react";
import Navigation from "../components/Navigation";

const Secret = () => {
  return (
    <React.Fragment>
      <Navigation />
      <h1 style={{textAlign: "center"}}>I'm building this page, please come back later.</h1>
    </React.Fragment>
  );
};

export default Secret;
