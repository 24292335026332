import React, { useEffect, useRef } from "react";
import Navigation from "../components/Navigation";
import Achen from "../components/Achen";
import WorkExp from "../components/WorkExp";
import About from "../components/About";
import Skill from "../components/Skill";
import Project from "../components/Project";


const Home = () => {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 0.5, // Adjust the threshold as needed
  //   };
  //   const callback = (entries, observer) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // Start playing the video when it enters the viewport
  //         videoRef.current.play();
  //       } else {
  //         // Pause the video when it is outside the viewport
  //         videoRef.current.pause();
  //       }
  //     });
  //   };
  //   const observer = new IntersectionObserver(callback, options);
  //   observer.observe(videoRef.current);

  //   // Clean up the observer when the component unmounts
  //   return () => observer.unobserve(videoRef.current);
  // }, []);

  return (
    <React.Fragment>
      <Navigation />
      <p style={{ textAlign: "center" }}>
        I'm keeping building this page, please come back later
      </p>
      <p style={{ textAlign: "center" }}>Latest update time: December 8th, 2023</p>
      <Achen />
      <About />
      <Skill />
      <WorkExp />
      <Project />
      
      {/* <footer style={{ textAlign: "center"}}>&#169;2023 Cheng Huang | Made by Cheng Huang</footer> */}
    </React.Fragment>
  );
};

export default Home;
