import React, { useState } from "react";
import classes from "./WorkExp.module.css";
import aum from "../assets/AUM.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import achen4 from "../assets/achen4.jpg";
import resumePdf from "../assets/resume.pdf";
import Email from "./Email";

const WorkExp = () => {
  const [resumeIsShown, setResumeIsShown] = useState(false);

  const showResumeHandler = () => {
    setResumeIsShown(true);
  };

  const hideResumeHandler = () => {
    setResumeIsShown(false);
  };

  return (
    <div className={classes.workExp}>
      <h1 className={classes.workTitle}>Work Experiences</h1>
      <VerticalTimeline className={classes.expContent}>
        <VerticalTimelineElement
          className={classes.expElement}
          // contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          // contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2022.8 - 2023.5"
          iconStyle={{ background: "lightseagreen", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <div className={classes.expImage}>
            <img src={aum} />
          </div>

          <h3 className="vertical-timeline-element-title">Instructor</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Auburn University at Montgomery
          </h4>
          <ul>
            <li>
              Developed and delivered curriculum on 3D modeling for
              undergraduate students.
            </li>
            <li>
              Provided personalized instruction to help students gain proFciency
              in C++ and MySQL databases.
            </li>
          </ul>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021.6 - 2022.7"
          iconStyle={{ background: "lightseagreen", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <div className={classes.expImage}>
            <img src={aum} />
          </div>
          <h3 className="vertical-timeline-element-title">
            Graduate Teacher Assistant
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Auburn University at Montgomery
          </h4>
          <ul>
            <li>
              Assisted in organizing and delivering courses for a variety of
              technical subjects, including (ront-end, Back-end, Cloud Computing
              )AWSU, C++, and MySQL databases.
            </li>
            <li>
              Provided direct support to students, helping them achieve their
              goals and succeed in their coursework.
            </li>
          </ul>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Time to download my resume?"
          iconStyle={{ background: "#f9c762", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <div className={classes.expImage}>
            <img src={achen4} />
          </div>
          <div className={classes.twoButtons}>
            <a href={resumePdf} download="Cheng Huang - Resume" target="_blank">
              <div className={classes.container}>
                <div className={classes.center}>
                  <button className={classes.btn}>
                    <svg
                      width="180px"
                      height="60px"
                      viewBox="0 0 180 60"
                      class="border"
                    >
                      <polyline
                        points="179,1 179,59 1,59 1,1 179,1"
                        class="bg-line"
                      />
                      <polyline
                        points="179,1 179,59 1,59 1,1 179,1"
                        class="hl-line"
                      />
                    </svg>
                    <span>Download Resume</span>
                  </button>
                </div>
              </div>
            </a>
            <div className={classes.container}>
              <div className={classes.center}>
                <button className={classes.btn} onClick={showResumeHandler}>
                  <svg
                    width="180px"
                    height="60px"
                    viewBox="0 0 180 60"
                    class="border"
                  >
                    <polyline
                      points="179,1 179,59 1,59 1,1 179,1"
                      class="bg-line"
                    />
                    <polyline
                      points="179,1 179,59 1,59 1,1 179,1"
                      class="hl-line"
                    />
                  </svg>
                  <span>Email me</span>
                </button>
              </div>
            </div>
            {resumeIsShown && <Email onHide={hideResumeHandler} />}
            <br />
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default WorkExp;
