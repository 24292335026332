import { useEffect, useState } from "react";
import classes from "./Greet.module.css";
import Typewriter from "typewriter-effect";

const Greet = () => {
  const [messages, setMessages] = useState(["Visitors", "HR", "Interviewers"]);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const nextIndex = (index + 1) % prevMessages.length;
        index = nextIndex;
        return prevMessages;
      });

      return () => clearInterval(interval);
    }, []);
  });

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good morning,");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good afternoon,");
    } else {
      setGreeting("Good evening,");
    }
  }, []);

  return (
    <div className={classes.word}>
      <h3>{greeting}</h3>
      <Typewriter
        className={classes.message}
        options={{
          strings: messages,
          autoStart: true,
          loop: true,
          loopCount: Infinity,
          deleteSpeed: "natural",
        }}
      />
      <div className={classes.name}>
        <span className={classes.text}>This is </span>
        <span className={classes.handwrittenUnderline}>Achen.</span>
        {/* <svg className={classes.line}
          width="128"
          height="27"
          viewBox="0 0 128 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.45989 16.3655C2.50206 16.3655 0.084615 14.2419 4.15944 13.1332C17.4193 9.52534 31.8237 8.71028 45.4141 7.177C62.2635 5.27604 79.4183 2.40623 96.3812 2.00071C97.6173 1.97116 116.123 2.86429 112.88 4.83353C94.6441 15.9094 70.0233 19.2364 49.6125 24.2408C43.3995 25.7641 62.4177 24.1293 68.7927 23.5995C87.71 22.0273 107.261 20.0186 126.195 22.0598"
            stroke="#14AE5C"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> */}
      </div>
    </div>
  );
};

export default Greet;
