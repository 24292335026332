import { useState } from "react";
import classes from "./Email.module.css";
import MyHome from "./MyHome";
import emailjs from "@emailjs/browser";

const Email = (props) => {
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform email sending logic here
    // You can use APIs or other methods to send the email
    emailjs.sendForm(
      "service_sglcimm",
      "template_12xajyj",
      e.target,
      "GDsceV8jMc8gXSbSU"
    );

    // Reset the form fields
    setText("");
    setEmail("");
    setMessage("");
  };


  return (
    <div className={classes.email}>
      <div className={classes.emailContent}>
        <div className={classes.emailMap}>
          <MyHome />
        </div>
        <div className={classes.contact}>
          <h2>Email Me</h2>
          <form className={classes.emailForm} onSubmit={handleSubmit}>
            <label>Mr/Ms:</label>
            <input
              type="text"
              name="name"
              value={text}
              onChange={handleTextChange}
              required
            />
            <br />

            <label>Email:</label>
            <input
              type="email"
              name="emailFrom"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <br />

            <label>Message:</label>
            <textarea
              name="message"
              value={message}
              onChange={handleMessageChange}
              required
            />
            <br />

            <button type="submit" className={classes.emailSend}>Send</button>
          </form>
          <br />
          <div className={classes.buttons}>
            <button onClick={props.onHide}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
