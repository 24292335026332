import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Secret from "./pages/Secret";
import Privacy from "./pages/Privacy";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "secret", element: <Secret /> },
  { path: "privacy", element: <Privacy /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
